var metric, imperial;

metric = {
  BPM: {
    name: {
      singular: "Respiration per minute",
      plural: "Respirations per minute",
    },
    to_anchor: 1, 
  },
  SPB: {
    name: {
      singular: "Second per breath",
      plural: "Seconds per breath",
    },
    to_anchor: 1 / 60, // 1 BPM = 60 seconds / breath
  },
};

imperial = {
  BPM: {
    name: {
      singular: "Respiration per minute",
      plural: "Respirations per minute",
    },
    to_anchor: 1, 
  },
  SPB: {
    name: {
      singular: "Second per breath",
      plural: "Seconds per breath",
    },
    to_anchor: 1 / 60, 
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "BPM",
      ratio: 1, 
    },
    imperial: {
      unit: "BPM",
      ratio: 1, 
    },
  },
};
