var metric, imperial;

metric = {
  lx: {
    name: {
      singular: "Lux",
      plural: "Lux",
    },
    to_anchor: 1,
  },
  klx: {
    name: {
      singular: "Kilolux",
      plural: "Kilolux",
    },
    to_anchor: 1000, 
  },
  ph: {
    name: {
      singular: "Phot",
      plural: "Phots",
    },
    to_anchor: 10000,
  },
  nox: {
    name: {
      singular: "Nox",
      plural: "Nox",
    },
    to_anchor: 0.001,
  },
};

imperial = {
  "ft-cd": {
    name: {
      singular: "Foot-candle",
      plural: "Foot-candles",
    },
    to_anchor: 1,
  },
  "m-cd": {
    name: {
      singular: "Meter-candle",
      plural: "Meter-candles",
    },
    to_anchor: 10.76391,
  },
  "lm-ft²": {
    name: {
      singular: "Lumen per square foot",
      plural: "Lumens per square foot",
    },
    to_anchor: 1,
  },
  "lm-in²": {
    name: {
      singular: "Lumen per square inch",
      plural: "Lumens per square inch",
    },
    to_anchor: 144,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "lx",
      ratio: 1 / 10.76391,
    },
    imperial: {
      unit: "ft-cd",
      ratio: 10.76391,
    },
  },
};
