var metric, imperial;

metric = {
  bpm: {
    name: {
      singular: "Beat per Minute",
      plural: "Beats per Minute",
    },
    to_anchor: 1, // Anchor unit for metric heart rate
  },
  Hz: {
    name: {
      singular: "Hertz",
      plural: "Hertz",
    },
    to_anchor: 60, // 1 Hz = 60 bpm
  },
};

imperial = {
  bph: {
    name: {
      singular: "Beat per Hour",
      plural: "Beats per Hour",
    },
    to_anchor: 1 / 60, // 1 bpm = 60 bph
  },
  bpm: {
    name: {
      singular: "Beat per Minute",
      plural: "Beats per Minute",
    },
    to_anchor: 1, // Same as metric bpm
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "bpm",
      ratio: 1, // Metric is based on bpm
    },
    imperial: {
      unit: "bpm",
      ratio: 1, // Imperial also uses bpm as its base
    },
  },
};
