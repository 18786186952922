var metric, imperial;

metric = {
  "T": {
    name: {
      singular: "Tesla",
      plural: "Teslas",
    },
    to_anchor: 1,
  },
  "mT": {
    name: {
      singular: "Millitesla",
      plural: "Milliteslas",
    },
    to_anchor: 0.001,
  },
  "µT": {
    name: {
      singular: "Microtesla",
      plural: "Microteslas",
    },
    to_anchor: 0.000001,
  },
  "G": {
    name: {
      singular: "Gauss",
      plural: "Gauss",
    },
    to_anchor: 0.0001, // 1 Gauss = 0.0001 Tesla
  },
};

imperial = {
  "G": {
    name: {
      singular: "Gauss",
      plural: "Gauss",
    },
    to_anchor: 1,
  },
  "mG": {
    name: {
      singular: "Milligauss",
      plural: "Milligauss",
    },
    to_anchor: 0.001,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "T",
      ratio: 10000, // 1 Tesla = 10000 Gauss
    },
    imperial: {
      unit: "G",
      ratio: 1 / 10000, // 1 Gauss = 0.0001 Tesla
    },
  },
};
