import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import { Form, Button, Card } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import logo from "../image/logo.png";
import "react-toastify/dist/ReactToastify.css";

import "./Login.css";

//API handling components
import { BASE_URL } from "./../../global";
import { useDispatch } from "react-redux";
import { logOut, logIn } from "./loginSlice";
import moment from "moment";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { userDesignation } from "../../constants/masterUserConstants";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 312,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "25px",
  display: "flex",
  flexDirection: "column",
};

const data = { userName: "admin", password: "admin" };

const blockTimeConst = 15;
export const ROLES = {
  1: { name: "Calibration Authority", id: 1 },
  2: { name: "Approved Authority", id: 2 },
  3: { name: "Reviewed By", id: 3, client: true },
  4: { name: "Signed By", id: 4, client: true },
  5: { name: "Witnessed By", id: 5, client: true },
};

const getStorage = (item, json) => {
  let val = localStorage.getItem(item);
  if (json)
    try {
      val = JSON.parse(val);
    } catch {}
  return val;
};
const setStorage = (item, val, json) => {
  try {
    val = JSON.stringify(val);
  } catch {}
  return localStorage.setItem(item, val);
};

export const useGetRoleInfo = (all, type) => {
  const storage_userType = localStorage.getItem("type");
  const storage_currentRole = getStorage("currentRole", true);
  const storage_roles = (getStorage("roles", true) || []).filter((e) => e);
  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState({});

  useEffect(() => {
    const role_trace = async () => {
      let tRoles = all
        ? [
            ...(type
              ? Object.values(ROLES).filter((e) =>
                  storage_userType == 3 ? e.client : !e.client
                )
              : Object.values(ROLES)),
          ]
        : storage_roles.filter((e) =>
            `${storage_userType}` === "3" ? e.client : !e.client
          );
      let tCurrentRole = { ...storage_currentRole };
      const roleSettings = await axiosWithToken
        .get(
          BASE_URL + `settings?_where=(keyName,like,role~)~and(status,ne,-1)`
        )
        .then((res) => res.data)
        .catch((err) => {
          console.log("setting fetch error : ", err);
          return false;
        });
      if (roleSettings) {
        let _settingList = {};
        roleSettings.forEach((setting) => {
          if (setting.keyName.startsWith("activeRole")) {
            _settingList[setting.keyName.split("activeRole")[1]] = {
              name: setting.value,
              id: setting.keyName.split("activeRole")[1],
            };
            if (setting.keyName.split("activeRole")[1] == currentRole?.id) {
              tCurrentRole = {
                name: setting.value,
                id: setting.keyName.split("activeRole")[1],
              };
            }
          }
        });
        tRoles = tRoles.map((e) => ({ ...e, ..._settingList[e.id] } || e));
        tRoles = tRoles.filter((e) => e.name.toLocaleLowerCase() !== "false");
      }
      setCurrentRole(tCurrentRole);
      setStorage("currentRole", tCurrentRole);
      setRoles(tRoles);
      setStorage("roles", tRoles);
    };
    role_trace();
  }, []);

  if (type) return [...roles];
  return {
    roles,
    currentRole,
    setCurrentRole: (newCurrentRole) => {
      setCurrentRole(newCurrentRole);
      setStorage("currentRole", newCurrentRole);
    },
    setRoles: (newRoles) => {
      setRoles(newRoles);
      setStorage("roles", newRoles);
    },
  };
};

//Commented on 27/10/23
//Need to remove after few date, kept to reiew
// export const useGetAllWillBeUsedRoles = () => {
//   const [Roles, setRoles] = useState([...Object.values(ROLES).map((e) => e)]);

//   useEffect(() => {
//     let asyncFn = async () => {
//       let tmp = await axiosToken
//         .get(
//           BASE_URL + `settings?_where=(keyName,like,role~)~and(status,ne,-1)`
//         )
//         .then((res) => res.data)
//         .catch((err) => {
//           console.log("setting fetch error : ", err);
//           return false;
//         });

//       if (tmp) {
//         let _settingList = {};
//         tmp.forEach((setting) => {
//           if (setting.keyName.startsWith("activeRole")) {
//             _settingList[setting.keyName.split("activeRole")[1]] = {
//               name: setting.value,
//               id: setting.keyName.split("activeRole")[1],
//             };
//           }
//         });

//         let tRoles = Roles.map((e) => ({ ...e, ..._settingList[e.id] } || e));
//         tRoles = tRoles.filter((e) => e.name.toLocaleLowerCase() !== "false");
//         setRoles(tRoles);
//       }
//     };
//     asyncFn();
//   }, []);

//   return Roles;
// };

export const addloginEntryInAudit = (action, userId, dateAndTime) => {
  let payload = {
    type: "Login History",
    action: action,
    userId: userId,
    change_date:dateAndTime,
  };

  axiosWithToken
    .post(BASE_URL + `audit_log`, payload)
    .then((res) => {
      console.log("Login History added in Audit");
    })
    .catch((error) => {
      toast.error("Something Went Wrong!");
    });
};

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const { setCurrentRole, setRoles } = useGetRoleInfo();

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const configure = async (
    _id,
    _userName,
    type,
    modules = "",
    clientId = 0,
    companies,
    branch,
    roles,
    designation,
    companyHierarchy,
    editAccess,
    disciplineID
  ) => {
    localStorage.clear();
    sessionStorage.setItem("isLoggedIn", true);
    localStorage.setItem("id", _id);
    localStorage.setItem("clientId", clientId);
    localStorage.setItem("userName", _userName);
    localStorage.setItem("type", type);
    localStorage.setItem("modules", modules);
    localStorage.setItem("validClients", companies);
    localStorage.setItem("branch", branch);
    localStorage.setItem("designationIds", designation);
    localStorage.setItem("companyHierarchy", companyHierarchy);
    localStorage.setItem("editAccess", editAccess);
    localStorage.setItem("disciplineID", disciplineID);

    const firstDesignation = userDesignation?.find(
      (d) => d.id === parseInt((designation || "")?.split(",")[0])
    );
    localStorage.setItem(
      "designation",
      firstDesignation ? firstDesignation?.name : ""
    );

    let tRoles = (roles?.split(",") || [])
      .filter((e) => (`${type}` === "3" ? ROLES[e].client : !ROLES[e].client))
      .map((e) => ROLES[e]);
    const roleSettings = await axiosWithToken
      .get(BASE_URL + `settings?_where=(keyName,like,role~)~and(status,ne,-1)`)
      .then((res) => res.data)
      .catch((err) => {
        console.log("setting fetch error : ", err);
        return false;
      });
    if (roleSettings) {
      let _settingList = {};
      roleSettings.forEach((setting) => {
        if (setting.keyName.startsWith("activeRole")) {
          _settingList[setting.keyName.split("activeRole")[1]] = {
            name: setting.value,
            id: setting.keyName.split("activeRole")[1],
          };
        }
      });
      tRoles = tRoles.map((e) => ({ ...e, ..._settingList[e.id] } || e));
      tRoles = tRoles.filter((e) => e.name.toLocaleLowerCase() !== "false");
    }
    let tCurrentRole = tRoles.filter((e) => e.id == tRoles?.[0]?.id)?.[0] || {};
    setCurrentRole(tCurrentRole);
    setRoles(tRoles);
    toast.success("Login Successful!");
  };

  const renderModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
            }}
          >
            <span>Kindly contact your admin to reset your credentials!</span>
          </div>
        </Box>
      </Modal>
    );
  };



  const handleSubmit = (e) => {
    axiosWithToken
      .post(BASE_URL + `login`, { userName: userName, password: password })
      .then((res) => {
        // console.log("res.data.token",res.data.token)
        const authToken = res.data.token;
        sessionStorage.setItem("authToken", authToken);
        sessionStorage.setItem("logInUser", res.data?.user?.userName);
        sessionStorage.setItem("chatBoatAPI", BASE_URL);

        if (res.status === 200) {
          axiosWithToken
            .get(
              BASE_URL +
                `userClientAssign?_where=(userId,eq,${
                  res.data?.user?.id
                })~and(endDate,gt,${moment(new Date()).format("YYYY-MM-DD")})`
            )
            .then(async (res2) => {
              // console.log("client company mapping : ", res.data);
              await configure(
                res.data?.user?.id,
                res.data?.user?.userName,
                res.data?.user?.type,
                res.data?.user?.modules,
                res.data?.user?.clientId,
                res2.data.map((e) => e.companyId).join(","),
                res.data?.user?.branch,
                res.data?.user?.roles || null,
                res.data?.user?.designation,
                res.data?.user?.companyHierarchy,
                res.data?.user?.editAccess,
                res.data?.user?.disciplineID
              );
              
              await addloginEntryInAudit("login", res.data?.user?.id, moment().format('YYYY-MM-DD HH:mm:ss'));

              if (res.data.length)
                dispatch(
                  logIn({
                    user: res.data?.user,
                  })
                );
              else dispatch(logOut());
              props.setIsLoggedIn(true);
              if (res.data?.user?.type != 3)
                navigate("/dashboard", { replace: true });
              else navigate("/clientDashboard", { replace: true });
              if ("caches" in window) {
                caches.keys().then((cacheNames) => {
                  cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                  });
                });
              }
              window.location.reload(true);
            });
        } else {
          let falseLoginCount = localStorage.getItem("loginCount") || 0;
          setUserName("");
          setPassword("");
          localStorage.setItem("loginCount", Number(falseLoginCount) + 1);
          if (Number(falseLoginCount) === 2) {
            let blockTime = Date.now() + blockTimeConst * 60 * 1000;
            localStorage.setItem("blockTimer", blockTime);
          }
          setIsLoading(false);
          checkTimer();
        }

        setIsLoading(false);
      })
      .catch((error) => {
          toast.error("Incorrect Username / password");
          setTimeout(() => {
            window.location.reload(); 
        }, 700);
        setError(error.response.data.error);
      });
  };

  const checkTimer = () => {
    let blockCount = Number(localStorage.getItem("loginCount")) || 0;
    if (blockCount > 2) {
      let blockTime =
        ((Number(localStorage.getItem("blockTimer")) || 0) - Date.now()) / 1000;
      blockTime = blockTime > 0 ? blockTime : 0;
      if (blockTime && !timer)
        setInterval(() => {
          checkTimer();
        }, 1000);
      if (blockTime)
        setError(
          `Please try again after ${parseInt(
            `${blockTime / 60}`
          )} minutes and ${parseInt(`${blockTime % 60}`)} seconds ${
            blockCount < 3 ? `(${3 - blockCount} tries remaining)` : ""
          }`
        );
      else {
        localStorage.setItem("loginCount", 0);
        localStorage.setItem("blockTimer", -1);
        if (timer) clearInterval(timer);
        setError("");
      }
    } else if (blockCount > 0) {
      setError(
        `Incorrect Username / password \n  ${
          blockCount < 3 ? `(${3 - blockCount} tries remaining)` : ""
        }`
      );
    } else setError("");
  };

  useEffect(() => {
    checkTimer();
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-pic js-tilt" data-tilt>
              <img className="" src={logo} alt="IMG" />
            </div>

            <div className="login100-form validate-form">
              <span className="login100-form-title">Login</span>

              <div
                className="wrap-input100 validate-input"
                data-validate="Valid email is required: ex@abc.xyz"
              >
                <input
                  className="input100"
                  type="text"
                  name="email"
                  placeholder="Username"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  disabled={
                    Number(localStorage.getItem("loginCount") || 0) === 3
                  }
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <input
                  className="input100"
                  type={showPassword ? "text" : "password"}
                  name="pass"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={
                    Number(localStorage.getItem("loginCount") || 0) === 3
                  }
                />
                <span className="focus-input100"></span>
                <span
                  className="symbol-input100"
                  onMouseEnter={() => setShowPassword(true)}
                  onMouseLeave={() => setShowPassword(false)}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
              <span style={{ color: "red" }}>{error}</span>

              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn"
                  onClick={() => {
                    setIsLoading(true);
                    handleSubmit();
                  }}
                  disabled={
                    isLoading || localStorage.getItem("blockTimer") > Date.now()
                      ? true
                      : false ||
                        (Number(localStorage.getItem("loginCount")) || 0) ===
                          3 ||
                        isLoading
                  }
                >
                  Login
                </button>
              </div>
              <div className="text-center p-t-12">
                <span className="txt1">Forgot</span>
                <button
                  className="txt2"
                  style={{ backgroundColor: "#0000" }}
                  onClick={(e) => handleOpen(true)}
                >
                  Username / Password?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  );
}

export default Login;
