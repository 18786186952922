import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import moment from "moment";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { notifiy } from "../notification/Notification";
import { generateNumber } from "../../utils/utils";
const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
let designation = localStorage.getItem("designation");

async function ServeDates() {
  try {
    const url = `${BASE_URL}dynamic`;
    const query = `SELECT now() as date`;
    const response = await axiosWithToken.post(url, { query });
    return new Date(response.data[0]?.date || null);
  } catch (error) {
    console.error(error);
    return 0;
  }
}

export const ChangeCertificateStatus = async (
  action,
  id,
  calibratedby,
  instrumentName,
  DUCID,
  rejectionMessage,
  usercurrentRole,
  approvedby,
  settingList,
  allocatedULRNo,
  calType
) => {
   let url = BASE_URL;
  let data = {};
  let dateTime = await ServeDates();
  let updatedULRNo = null;
  if(settingList?.["ManualURL"] == "false"){
    let ulrLastDigit = settingList?.["LASTULRNODIGIT"]; 
    updatedULRNo = generateNumber(
      settingList?.["ULR number"],
      ulrLastDigit
    )
  }

  switch (action) {
    case "approve":
      data.status = 3;
      data.approvedby = Number(user[0]);
      data.approvedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      data.designation = designation;
      if (settingList?.["ManualURL"] == "false" && allocatedULRNo == 0 && calType == 1) {
        data.allocatedULRNo = 1;
        data.ULRNo = updatedULRNo;
      }
      break;
    case "reject":
      data.status = 4;
      data.approvedby = null;
      data.reviewedBy = null;
      data.signedBy = null;
      data.witnessBy = null;
      data.rejectedBy = Number(user[0]);
      data.approvedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      break;
    case "review":
      data.status = 5;
      data.reviewedBy = user[0];
      data.reviewedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      break;
    case "sign":
      data.status = 6;
      data.signedBy = Number(user[0]);
      data.signedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      break;
    case "witness":
      data.status = 7;
      data.witnessBy = Number(user[0]);
      data.witnessDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      break;
    default:
      break;
  }

  id?.map((e) => {
    axiosWithToken
      .patch(url + `certificates/${e}`, data)
      .then(async (res) => {
        toast.success(
          `Certificate is ${
            {
              approve: "Approved",
              reject: "Rejected",
              review: "Review",
              sign: "Signed",
              witness: "Witness",
            }[action]
          } successfully!`
        );

        if (action === "approve") {
          updateSRFAnalytics([e], 80);
        }
        //TODO: Need to update _to once notification module is perfected.
        if (action === "reject") {
          const notification = {
            _from: user[0],
            _to: calibratedby[0],
            body: `${instrumentName}(${DUCID}) certificate rejected (${usercurrentRole}) :\n ${rejectionMessage}`,
            type: 1,
          };
          notifiy(notification, () => {
            console.log(`Notified of rejection to ${calibratedby[1]}`);
          });
          if (approvedby[0] && calibratedby[0] !== approvedby[0]) {
            const secondNotification = {
              _from: user[0],
              _to: approvedby[0],
              body: `${instrumentName}(${DUCID}) certificate rejected (${usercurrentRole}) :\n ${rejectionMessage}`,
              type: 1,
            };
            notifiy(secondNotification, () => {
              console.log(`Notified of rejection to ${approvedby[1]}`);
            });
          }
          setTimeout(window.location.reload(false), 500);
        } else {
          if (settingList?.["ManualURL"] == "false" && allocatedULRNo === 0) {
            const query = { query: `UPDATE settings SET value = ${Number(settingList?.["LASTULRNODIGIT"]) + 1} WHERE keyName = 'LASTULRNODIGIT';` };
            await axiosWithToken.post(BASE_URL + `dynamic`, query);
          }
          setTimeout(window.location.reload(false), 500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
