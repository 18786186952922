var conductivity = {
  S: {
    name: {
      singular: "siemens",
      plural: "siemens",
    },
    to_anchor: 1,
  },
  mS: {
    name: {
      singular: "millisiemens",
      plural: "millisiemens",
    },
    to_anchor: 1 / 1000,
  },
  µS: {
    name: {
      singular: "microsiemens",
      plural: "microsiemens",
    },
    to_anchor: 1 / 1000000,
  },
  S_per_cm: {
    name: {
      singular: "siemens per centimeter",
      plural: "siemens per centimeter",
    },
    to_anchor: 1,
  },
  S_per_m: {
    name: {
      singular: "siemens per meter",
      plural: "siemens per meter",
    },
    to_anchor: 1 / 100,
  },
  "mS/cm": {
    name: {
      singular: "millisiemens per centimeter",
      plural: "millisiemens per centimeter",
    },
    to_anchor: 1 / 1000,
  },
  "µS/cm": {
    name: {
      singular: "microsiemens per centimeter",
      plural: "microsiemens per centimeter",
    },
    to_anchor: 1 / 1000000,
  },
};

module.exports = {
  metric: conductivity,
  _anchors: {
    conductivity: {
      unit: "S",
      ratio: 1,
    },
  },
};
