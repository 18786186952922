var metric, imperial;

metric = {
  pF: {
    name: {
      singular: "Picofarad",
      plural: "Picofarads",
    },
    to_anchor: 1 / 1000000000000,
  },
  nF: {
    name: {
      singular: "Nanofarad",
      plural: "Nanofarads",
    },
    to_anchor: 1 / 1000000000,
  },
  µF: {
    name: {
      singular: "Microfarad",
      plural: "Microfarads",
    },
    to_anchor: 1 / 1000000,
  },
  mF: {
    name: {
      singular: "Millifarad",
      plural: "Millifarads",
    },
    to_anchor: 1 / 1000,
  },
  F: {
    name: {
      singular: "Farad",
      plural: "Farads",
    },
    to_anchor: 1,
  },
  kF: {
    name: {
      singular: "Kilofarad",
      plural: "Kilofarads",
    },
    to_anchor: 1000,
  },
};

imperial = {
  "inF": {
    name: {
      singular: "Inch Farad",
      plural: "Inch Farads",
    },
    to_anchor: 1 / 12,  // Approximate conversion, you can adjust as needed
  },
  "ftF": {
    name: {
      singular: "Foot Farad",
      plural: "Foot Farads",
    },
    to_anchor: 1, // Approximate conversion for foot Farad
  },
  "miF": {
    name: {
      singular: "Mile Farad",
      plural: "Mile Farads",
    },
    to_anchor: 5280, // Approximate conversion for mile Farad
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "F",
      ratio: 1 / 1,
    },
    imperial: {
      unit: "ftF",
      ratio: 1,
    },
  },
};
